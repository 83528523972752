<template>
    <div class="card">
        <h5 class="mb-5 pb-3">Detail User</h5>

        <h6 class="text-black roboto-medium">Biodata</h6>
        <hr>
        <div class="overflow-auto mb-5">
            <table class="table">
                <tbody>
                    <tr>
                        <td>Nama Lengkap</td>
                        <td v-if="loading"><Skeleton width="100px" /></td>
                        <td v-else>
                            {{ data.fullname }}
                        </td>
                    </tr>
                    <tr>
                        <td>Tanggal Daftar</td>
                        <td v-if="loading"><Skeleton width="150px" /></td>
                        <td v-else>
                            {{ moment(data.created_at).format('DD MMM YYYY HH:mm') }} WIB
                        </td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td v-if="loading"><Skeleton width="100px" /></td>
                        <td v-else>
                            <Tag class="mr-2 py-0 text-10 bg-linear-green border-round-lg" value="Aktif" v-if="data.status"></Tag>
                            <Tag class="mr-2 py-0 text-10 border-round-lg bg-linear-red" value="Tidak Aktif" v-else></Tag>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h6 class="text-black roboto-medium">Akun</h6>
        <hr>
        <div class="overflow-auto mb-5">
            <table class="table">
                <tbody>
                    <tr>
                        <td>Username</td>
                        <td v-if="loading"><Skeleton width="100px" /></td>
                        <td v-else>
                            {{ data.username }}
                        </td>
                    </tr>
                    <tr>
                        <td>Login Terakhir</td>
                        <td v-if="loading"><Skeleton width="100px" /></td>
                        <td v-else>
                            {{ data.last_login !== '0001-01-01T00:00:00Z' ? `${moment(data.last_login).format('DD MMM YYYY HH:mm:ss')} WIB` : '-' }}
                        </td>
                    </tr>
                    <tr>
                        <td>Updated</td>
                        <td v-if="loading"><Skeleton width="150px" /></td>
                        <td v-else>
                            {{ data.updated_at !== '0001-01-01T00:00:00Z' ? `${moment(data.updated_at).format('DD MMM YYYY HH:mm:ss')} WIB` : '-' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'user-detail',
    props: ['id'],
    data() {
        return {
            moment: require('moment-timezone'),
            loading: false,
            data: {},
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            this.$http
                .get(`${process.env.VUE_APP_API_URL}/users/${this.id}`)
                .then(response => {
                    this.loading = false
                    this.data = response.data.data
                })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables';

.table {
    td {
        color: $black;
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
    tr td:first-child{
        width: 200px;
        opacity: .6;
        @media (max-width: 575px) {
            width: 45%;
        }
    }
}
</style>
